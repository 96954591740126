.login_form {
  width: 450px;
  box-shadow: 2px 2px 4px rgba(0, 21, 41, 0.12);
  padding: 1rem 3.5rem;
  border: solid 1px #d9d9d9;
  border-radius: 6px;
  background-color: white
}

.login_form_button {
  width: 100%;
  height: 40px;
  margin: 1rem auto;
  font-weight: bold
}

.header{
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
}

.goto_register{
  text-align: right;
}

.or{
  height: 2px;
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 14px;
}


