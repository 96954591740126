.login_form {
  width: 450px;
  box-shadow: 2px 2px 4px rgba(0, 21, 41, 0.12);
  padding: 1rem 3.5rem;
  border: solid 1px #d9d9d9;
  border-radius: 6px;
  background-color: white;
}
.login_form_forgot {
  float: right;
}
.login_form_button {
  width: 100%;
  height: 40px;
  margin: 1rem auto;
  font-weight: bold;
}

.header {
  text-align: center;
  margin: 3rem auto;
  font-weight: bold;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.85);
}

.goto_register {
  text-align: right;
}

.or {
  /*height: 2px;*/
  color: rgba(0, 0, 0, 0.45) !important;
  margin-top: 0 !important;
  /*font-size: 14px;*/
}

.no_store_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.no_of_stores {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}

.subscriptioamt_div {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 20px;
}

.subscriptioamt_value {
  color: black;
  margin-left: 30px;
}

.switch_btn {
  
}

.dropdown_span {
  color: rgba(0, 0, 0, 0.45);
}
.oauth {
  box-shadow: none !important;
  border: 1px solid #cecece !important;
  border-radius: 5px !important;
  width: 100%;
  flex-direction: column;
}
.oauth > div {
  padding: 5px !important;
}
.oauth > span {
  padding: 5px !important;
}
