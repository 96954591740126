.card_main {
  margin: 24px;
  max-width: 100%;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
  color: #4076D9;
}

.card_main_div {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert {
  color: #ff0000;
  font-weight: 600;
}

.header{
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.85);
  width: 75%;
}

.green {
  color: #7ED321;
}

.red {
  color: #F5222D;
}



